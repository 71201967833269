/* top */
#top #top-left-links ul li a, #top #top-left-links ul li a:visited {
  border: transparent; }

#top #top-left-links ul li a:hover, #top #top-right-links > ul > li:hover {
  border-bottom: transparent; }

/* search */
#search .cats-button {
  display: none; }

button#oct-search-button {
  padding: 10px 16px !important;
  background: #f4ae1c !important; }
  button#oct-search-button i {
    color: #fff !important; }

#oct-bluring-box .menu-row {
  background: #fff; }

#menu .see-all {
  color: #11285c !important; }

.common-home .oct-slideshow-box {
  padding: 0;
  margin: 0 !important; }
  .common-home .oct-slideshow-box .owl-wrapper-outer {
    padding: 0; }
  .common-home .oct-slideshow-box .item {
    padding: 0; }

.common-home h2 {
  text-align: center;
  font-family: 'OpenSansRegular';
  text-transform: uppercase;
  font-size: 24px;
  color: #11285c;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative; }

.oct-category-item-box {
  padding: 22px 15px;
  margin-bottom: 24px;
  height: auto;
  min-height: 320px; }
  .oct-category-item-box .oct-category-item-text {
    font-size: 17px;
    min-height: 49px;
    text-align: center; }

.main-advantage-item-button {
  text-align: center; }
  .main-advantage-item-button a {
    display: inline-block;
    margin: 0 auto;
    padding: 6px 24px 6px;
    text-align: center;
    background: #11285c;
    color: #fff; }

.product-grid .product-thumb {
  text-align: center; }

.product-grid .product-thumb .cart a {
  display: block; }
  .product-grid .product-thumb .cart a:hover {
    background: #f4ae1c !important;
    color: #fff !important; }

.after-header-item a {
  color: #11285c; }

a.oct-button.button-one-click {
  width: 50%; }

a.oct-button.button-wishlist,
a.oct-button.button-compare {
  display: none; }

.account-address-add .form-group.custom-field {
  display: none; }

.row.sort-row {
  background: transparent; }

a.wishlist.oct-button.current-link,
a.compare.oct-button.current-link {
  display: none !important; }

a.wishlist.oct-button,
a.compare.oct-button {
  display: none !important; }

.oct-carousel-row .cart a {
  display: block;
  width: 80%; }
